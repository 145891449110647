import * as React from 'react';
import { Link } from 'gatsby';
import ButtonGoldBlackComponent from '../components/button-gold-black';

const ContactUsContainer = () => (
  <>
    <div className="text-2xl lg:text-4xl text-blue-600 mb-8">Skontaktuj się z nami</div>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <Link to="/kontakt">
        <ButtonGoldBlackComponent title="Napisz do nas" subtitle="na adres biuro@belgravia.edu.pl" />
      </Link>
      <Link to="/kontakt">
        <ButtonGoldBlackComponent title="Odwiedź nas" subtitle="na Woli Justowskiej w Krakowie" />
      </Link>
    </div>
  </>
);

export default ContactUsContainer;
